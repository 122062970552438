.navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    color: white;

}

.navbar-logo {
    height: 40px;
    margin-left: 20px;
}

.navbar-links {
    display: flex;
}

.navbar-link {
    cursor: pointer;
    display: block;
    padding: 0 20px;
    line-height: 60px;
    text-decoration: none;
    color: white;
}

.navbar-link:hover {
    background-color: #444;
}

.dropdown {
    /* background: rgba(0, 0, 0, 0.4); */
    font-family: "Arial Narrow", Arial, sans-serif;
    margin-left: 20px;
    /* height: 20px; */
    text-align: center;

}

.bellicon {
    display: flex;
}

.dropdown-option {
    /* margin-top: 10px; */
    position: absolute;
    z-index: 0;
    display: none;
    text-align: left;
    cursor: pointer;
}

.dropdown-option .span-option {
    display: block;
    padding: 8px 20px 2px 7px;
    background-color: #e50914;
}

.dropdown-option .span-option:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-option {
    display: block;

}

.navbar-search-form {
    display: flex;
}

.navbar-search-input {
    border: none;
    background-color: #282828;
    outline: none;
    color: white;
    border-radius: 5px;
    padding: 0 10px;
    line-height: 40px;
}

.navbar-search-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    line-height: 40px;
}

.sidenav {
    display: none;
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 60px;
}

.hambartogle {
    display: none;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.nav-logo-sidenav {
    top: 0;
    height: 40px;
    position: absolute;
    margin-top: 10px;
    margin-left: 7px;
}

@media screen and (max-width: 600px) {
    .navbar-logo {
        display: none;
    }

    .navbar-links {
        display: none;
    }

    .bellicon {
        display: none;
    }

    .dropdown {
        display: none;
    }

    .logoutbi {
        display: none;
    }

    .navbar-search-button {
        margin-right: 10px;
    }

    .sidenav {
        display: block;
        height: 100%;
        width: 200px;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        padding-top: 60px;
    }

    .hambartogle {
        display: block;
        margin-left: 10px;
        font-size: large;
        padding: 0 5px;
        outline: none;
    }


}
