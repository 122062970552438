/* scroll bar modification */
::-webkit-scrollbar {
    width: 8px;
}

.font-kepler {
    font-family: "Arial Narrow", Arial, sans-serif !important;
}

/* track */
::-webkit-scrollbar-track {
    background-color: rgb(60, 53, 53);
}

/* handle */
::-webkit-scrollbar-thumb {
    background-color: #d1001c;
    border-radius: 20px;
    width: 5px;
}

.scrolling-text-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.scrolling-text {
    display: inline-block;
    animation: scroll-left 10s linear infinite;
    display: flex;
    align-items: center;
}

.scrolling-text:hover {
    animation-play-state: paused;
}


@keyframes scroll-left {
    0% {
        transform: translateX(200%);
    }

    100% {
        transform: translateX(-300%);
    }
}

.whats-app {
    position: fixed;
    width: 100px;
    /* height: 50px; */
    bottom: 200px;
    right: 15px;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
}


.leading-tight {
    line-height: 1.25;
    color: #767171 !important;
    font-size: 22px;
    margin-top: 20px;
}

.mt-1.font-kepler.normal-case {
    border-bottom: 1px dotted #777;
    font-size: 18px;
    margin-top: 8px;
    padding-bottom: 8px;
}

.mt-1.font-kepler.normal-case::before {
    color: #b91c1c;
    content: "\f00c ";
    font-family: FontAwesome, sans-serif !important;
    margin-right: 6px;
}

.font-kepler.tracking-wide.text-xl {
    color: #767171 !important;
    font-size: 22px;
    line-height: 1.25;
    margin-top: 20px;
    font-weight: bold;
}

.list-disc li::before {
    color: #b91c1c;
    content: "\f00c ";
    font-family: FontAwesome, sans-serif !important;
    margin-right: 6px;
}

.list-disc li {
    border-bottom: 1px dotted #777;
    font-size: 18px;
    margin-top: 8px;
    padding-bottom: 8px;
}

.list-disc li:last-child {
    border-bottom: none;
}

.list-disc {
    list-style: none;
    margin: 0;
}

.font-kepler.cursor-pointer {
    font-size: 18px;
}

.mt-1.font-kepler.normal-case {
    font-size: 18px;
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px dotted #777;
}

.mt-1.font-kepler.normal-case::before {
    color: rgb(185, 28, 28);
    content: "\f00c ";
    font-family: FontAwesome, sans-serif !important;
    margin-right: 6px;
}


.mt-1.font-kepler.normal-case:last-child {
    border-bottom: none;
}

.leading-tight {
    color: #767171 !important;
    font-size: 22px;
    line-height: 1.25;
    margin-top: 20px;
    border-bottom: 2px solid #000;
}

.font-kepler.cursor-pointer {
    font-size: 20px;
}

.mt-1.font-kepler.normal-case {
    border-bottom: 1px dotted #777;
    font-size: 18px;
    margin-top: 12px;
    padding-bottom: 12px;
}

.truncate {
    font-weight: 500;
    line-height: 1;
    font-size: 16px !important;
}
