.glassBox {
    box-sizing: border-box;
    transition: 400ms;
    width: 300px;
    backdrop-filter: blur(2px);
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.glassBox__imgBox {
    display: block;
    width: 100%;
    transition: 400ms;
    height: 200px;
    aspect-ratio: 5/2;
    object-fit: contain;
}

.productcardimg {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: block;
    width: 100%;
    transition: 400ms;
    height: 200px;
    box-shadow: 0 10px 8px -10px #000000;
    object-fit: contain;
    /* mix-blend-mode: color-burn; */
}

.glassBox__title {
    text-align: center;
    margin-top: 15px;
    color: black;
    font-size: 18px;
    font-weight: 400;
    font-family: "Arial Narrow", Arial, sans-serif;
    transition: 400ms;
}

.glassBox__content {
    position: absolute;
    right: 15px;
    bottom: 15px;
    left: 15px;
    text-align: center;
    color: black;
    font-size: 14px;
    font-family: "Arial Narrow", Arial, sans-serif;
    letter-spacing: 0.1em;
    opacity: 0;
    transition: 100ms;
}

.glassBox:hover .glassBox__imgBox {
    transform: translateY(-50px);
    transition: 400ms;
}
